<template>

    <a-layout id="components-layout-demo-fixed-sider">
        <a-layout-sider :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }">
            <div class="logo">组件</div>
            <a-menu theme="dark" mode="inline" :default-selected-keys="['4']">
                <a-menu-item v-for="(item,index) in navList" :key="index">
                    <a href="javascript:;" @click="handleNavigateEvent(item.id)">{{item.name}}</a>
                </a-menu-item>
            </a-menu>
        </a-layout-sider>
        <a-layout class="flex flex-column" :style="{ marginLeft: '200px' }">
            <a-layout-header :style="{ background: '#fff', padding: 0 }">
                <div class="fs24 strong">demo演示案例</div>
            </a-layout-header>
            <a-layout-content class="flex-item" :style="{ margin: '24px 16px 0', overflow: 'auto' }">
                <div :style="{ padding: '24px', background: '#fff', textAlign: 'center' }">
                    <div class="container">
                        <h1 class="t-c strong mt30">
                            组件演示demo，具体参数请看组件详情
                        </h1>
                        <hr />

                        <!-- 标题组件  -->
                        <a-card id="commonTitle" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="标题组件">
                            <p class="t-l widget-name">组件地址：@/components/common-title.vue</p>
                            <common-title title="测试标题"></common-title>
                        </a-card>

                        <!-- 搜索 -->
                        <a-card id="search" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="搜索">
                            <p class="t-l widget-name">组件地址：@/components/search.vue</p>
                            <search placeholder="资讯、会议"></search>
                        </a-card>

                        <!-- 筛选组件 -->
                        <a-card id="filterTab" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="筛选列表">
                            <p class="t-l widget-name">组件地址：@/components/filter-tab.vue</p>
                            <filter-tab title="地区" :isShowMore="true" :list="[{id:1,name:'北京'},{id:2,name:'天津'},{id:3,name:'河北'},{id:4,name:'辽宁'}]"></filter-tab>
                        </a-card>

                        <!-- 横版图文列表 -->
                        <a-card id="acrossMediaItem" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="横版图文列表">
                            <p class="t-l widget-name">组件地址：@/components/across-media-item.vue</p>
                            <template v-for="(item,index) in 2">
                                <across-media-item :key="index" :item="{id:1,title:'测试标题',desc:'测试标题测试标题测试标题测试标题测试标题测试标题测试标题',url:'/',img:'https://files.sciconf.cn/medcon/2022/01/20220105/2022010509180794857136210.jpg'}">
                                    <template v-slot:bottom>
                                        <div class="fs12 color999">
                                            <span class="fs20">3</span>
                                            <span>万次阅读</span>
                                        </div>
                                    </template>
                                </across-media-item>
                            </template>
                        </a-card>

                        <!-- 竖版图文列表 -->
                        <a-card id="verticalMediaItem" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="竖版图文列表">
                            <p class="t-l widget-name">组件地址：@/components/vertical-media-item.vue</p>
                            <vertical-media-item :item="{id:1,title:'子宫内膜癌保育治疗探索子宫内膜癌保育治疗探索',name:'王建六',department:'妇产科',org:'北京大学人民医院',img:'https://files.sciconf.cn/medcon/2022/01/20220105/2022010509180794857136210.jpg'}"></vertical-media-item>
                            <vertical-media-item type="2" :item="{id:1,title:'子宫内膜癌保育治疗探索子宫内膜癌保育治疗探索',name:'王建六',department:'妇产科',org:'北京大学人民医院',img:'https://files.sciconf.cn/medcon/2022/01/20220105/2022010509180794857136210.jpg'}"></vertical-media-item>
                        </a-card>

                        <!-- 文本列表 -->
                        <a-card id="textItem" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="文本列表">
                            <p class="t-l widget-name">组件地址：@/components/text-item.vue</p>
                            <text-item type="1" gap="20" :showDesc="true" :item="{id:1,type:1,typeName:'',news_title:'2022 ERN GENTURIS临床实践指南：神经鞘瘤的诊断、治疗、管理以及监测',create_time:'2022-02-02',source:'欧洲罕见遗传肿瘤风险综合征网(ERN GENTURIS,European Reference Network-GENTURIS)'}"></text-item>
                            <text-item type="2" gap="20" :item="{id:1,class_type:0,typeName:'指南',news_title:'2022 ERN GENTURIS临床实践指南：神经鞘瘤的诊断、治疗、管理以及监测',create_time:'2022-02-02',source:'欧洲罕见遗传肿瘤风险综合征网(ERN GENTURIS,European Reference Network-GENTURIS)'}"></text-item>
                            <text-item type="3" gap="20" :item="{id:1,type:1,typeName:'',news_title:'2022 ERN GENTURIS临床实践指南：神经鞘瘤的诊断、治疗、管理以及监测',create_time:'2022-02-02',source:'欧洲罕见遗传肿瘤风险综合征网(ERN GENTURIS,European Reference Network-GENTURIS)'}"></text-item>
                        </a-card>

                        <!-- 直播列表 -->
                        <a-card id="liveItem" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="直播列表">
                            <p class="t-l widget-name">组件地址：@/components/live-item.vue</p>
                            <live-item :item="{id:1,live_banner:'/',title:'子宫内膜癌保育治疗探索子宫内膜癌保育治疗探索',hall_name:'线上',start_time:'2022-10-07 10:10:10',end_time:'2022-08-08 22:14:32',live_banner:'https://files.sciconf.cn/medcon/2022/01/20220105/2022010509180794857136210.jpg'}"></live-item>
                        </a-card>

                        <!-- 会议列表 -->
                        <a-card id="meetingItem" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="会议列表">
                            <p class="t-l widget-name">组件地址：@/components/meeting-item.vue</p>
                            <meeting-item :item="{id:1,url:'/',title:'子宫内膜癌保育治疗探索子宫内膜癌保育治疗探索',address:'线上',date:'2022-06-07 10:10:10',img:'https://files.sciconf.cn/medcon/2022/01/20220105/2022010509180794857136210.jpg'}"></meeting-item>
                            <meeting-item-row :data-list="[{title : '1'}]"></meeting-item-row>
                        </a-card>

                        <!-- 调查派列表 -->
                        <a-card id="surveyItem" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="调查派列表">
                            <p class="t-l widget-name">组件地址：@/components/survey-item.vue</p>
                            <template v-for="(item,index) in 2">
                                <survey-item :key="index" :item="{id:1,url:'/',title:'子宫内膜癌保育治疗探索子宫内膜癌保育治疗探索',img:'https://files.sciconf.cn/medcon/2022/01/20220105/2022010509180794857136210.jpg'}"></survey-item>
                            </template>
                        </a-card>

                        <!-- 专家列表 -->
                        <a-card id="expertList" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="医咖修专家列表">
                            <p class="t-l widget-name">组件地址：@/components/expert-list.vue</p>
                            <expert-list :dataList="expertData"></expert-list>
                        </a-card>

                        <!-- 推荐期刊列表 -->
                        <a-card id="journalItem" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="推荐期刊列表">
                            <p class="t-l widget-name">组件地址：@/components/journal-item.vue</p>
                            <journal-item style="width: 335px;" :journalItem="{img_url:'https://files.sciconf.cn/medcon/2021/04/20210419/2021041909085954108317269.jpg!scaling50',cn_name:'中国社区医师',desc:'Chinese community do',org:'贵阳中医学院',cn_code:'52-1081/R'}"></journal-item>
                        </a-card>

                        <!-- 精品课堂 -->
                        <a-card id="courserListItem" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="精品课堂">
                            <p class="t-l widget-name">组件地址：@/components/courser-list-item</p>
                            <course-item :data-list="courseList"></course-item>
                        </a-card>

                        <!-- 视频播放 -->
                        <a-card id="videoPlay" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="视频播放">
                            <p class="t-l widget-name">组件地址：@/components/video-play</p>
                            <video-play vid="4392459050bce1a1a3aa63eadca311a4_4"></video-play>
                        </a-card>
                        <!-- 留言评论 -->
                        <a-card id="commentList" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="留言评论">
                            <p class="t-l widget-name">组件地址：@/components/comment-list</p>
                            <comment-list style="width:800px;"></comment-list>
                        </a-card>
                        <!-- 热点资讯 -->
                        <a-card id="hotTopic" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="热点资讯">
                            <p class="t-l widget-name">组件地址：@/components/hot-topic-item</p>
                            <div v-for="(item,index) in reportList" :key="index" class="hot-topic-box" :class="{'mt20': index != 0}">
                                <hot-topic-item :showBottom="true" :item="item" class="bb">
                            </hot-topic-item>
                            </div>
                        </a-card>
                        <!-- 会议相册 -->
                        <a-card id="meetAlbum" class="mt30" :headStyle="{background:'#f1f1f1',fontSize:'20px',fontWeight:'bold'}" title="会议相册">
                            <p class="t-l widget-name">组件地址：@/components/album-item</p>
                            <div class="flex">
                                <album-item :data-list="[{images:[{image_url:''},{image_url:''},{image_url:''}]}]" lineCount="1"></album-item>
                                <album-item :data-list="[{images:[{image_url:''}]}]" lineCount="1"></album-item>
                                <album-item :data-list="[{images:[{image_url:''},{image_url:''}]}]" lineCount="1"></album-item>
                            </div>
                            
                        </a-card>
                    </div>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout>

</template>

<script>
import commonTitle from '@/components/common-title.vue';
import acrossMediaItem from '@/components/across-media-item.vue';
import filterTab from '@/components/filter-tab.vue';
import textItem from '@/components/text-item.vue';
import search from '@/components/search';
import verticalMediaItem from '@/components/vertical-media-item.vue'
import expertList from '@/components/expert-list'
import surveyItem from '@/components/survey-item.vue';
import liveItem from '@/components/live-item.vue';
import meetingItem from '@/components/meeting-item.vue';
import journalItem from '../../components/journal-item.vue';
import courseItem from '@/components/courser-list-item'
import meetingItemRow from '@/components/meeting-item-row'
import videoPlay from '@/components/video-player/video-play.vue'
import commentList from '@/components/comment-list';
import hotTopicItem from '@/components/hot-topic-item.vue';
import albumItem from '@/components/album-item.vue'

export default {
    name: 'Demo',
    components: {
        commonTitle,
        acrossMediaItem,
        filterTab,
        search,
        textItem,
        verticalMediaItem,
        expertList,
        surveyItem,
        liveItem,
        meetingItem,
        journalItem,
        courseItem,
        meetingItemRow,
        videoPlay,
        commentList,
        hotTopicItem,
        albumItem
    },
    data() {
        return {
            expertData: [
                { name: '曹彬' }
            ],
            courseList: [
                { name: '中华医学会' },
                { name: '中华医学会' },
                { name: '中华医学会' },
            ],
            navList: [
                {
                    id: 'commonTitle',
                    name: '通用标题',
                },
                {
                    id: 'search',
                    name: '搜索',
                },
                {
                    id: 'filterTab',
                    name: '筛选',
                },
                {
                    id: 'acrossMediaItem',
                    name: '横版图文列表',
                },
                {
                    id: 'verticalMediaItem',
                    name: '竖版图文列表',
                },
                {
                    id: 'textItem',
                    name: '文本列表',
                },
                {
                    id: 'liveItem',
                    name: '直播列表',
                },
                {
                    id: 'meetingItem',
                    name: '会议列表',
                },
                {
                    id: 'surveyItem',
                    name: '调查派列表',
                },
                {
                    id: 'expertList',
                    name: '专家列表',
                },
                {
                    id: 'journalItem',
                    name: '期刊列表',
                },
                {
                    id: 'courserListItem',
                    name: '课程列表',
                },
                {
                    id:'videoPlay',
                    name:'视频播放'
                },
                {
                    id:'commentList',
                    name:'留言评论'
                },
                {
                    id:'hotTopic',
                    name:'热点资讯'
                },
                {
                    id:'meetAlbum',
                    name:'会议相册'
                }
            ],
            reportList:[
                { id: 1, type: 1,information_type:'1', news_title: "第十二届北京睡眠高峰会议暨第二届中国发作性睡病及中枢性第二届中国",browsing:20,create_time:'2022-09-30',source:'第十二届北京睡眠高峰会议暨第二届中国发作性' },
                { id: 1, type: 2,information_type:'2', news_title: "第十二届北京睡眠高峰会议暨第二届中国发作性睡病及中枢性第二届中国",browsing:20,create_time:'2022-09-30',source:'第十二届北京睡眠高峰会议暨第二届中国发作性',images:['','',''] },
            ]
        }
    },
    methods: {
        handleNavigateEvent(id) {
            var PageId = document.querySelector('#' + id);
            PageId && window.scrollTo({
                top: PageId.offsetTop - 30,
            });
        }
    },
    mounted() {

    },
}
</script>
<style lang="less" scope>
.demo-title {
    border-bottom: 1px solid #ddd;
}
.logo {
    height: 64px;
    background: #fff;
    line-height: 64px;
    font-size: 24px;
    font-weight: bold;
    border-right: 1px solid #f1f1f1;
}
.widget-name{
    border: 1px solid #f8d481;
    background: #eee6de;
    padding:5px 20px;
    border-radius: 5px;
    font-size:16px;
}
.bb{
    border-bottom:1px solid #D8D8D8;
}
.hot-topic-box:last-child .bb{
    border-bottom: 0;
}
</style>
